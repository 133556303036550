import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import {
  Row,
  Col,
  Gap,
  Text,
  SEO,
  Title,
  Container,
  Button,
  Box,
  TapsBg,
  Waves,
  Link,
  Tooltip,
  Sup,
  ChatBot,
  ArticleThumbnail,
} from '../components'
import { extractKeystoneData } from '../lib/helpers'

import ToiletSrc from '../data/images/icons/toilet.svg'
import ShowerSrc from '../data/images/icons/shower.svg'
import BathSrc from '../data/images/icons/bath.svg'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.contentStartRef = React.createRef()
  }

  scrollToContent = () =>
    this.contentStartRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

  render() {
    const {
      location: { pathname },
      data,
    } = this.props

    const { allArticle = [] } = extractKeystoneData(data)
    const pathArticles = allArticle.filter(({ showOnHpPath }) => showOnHpPath).slice(0, 2)
    const qualityArticles = allArticle.filter(({ showOnHpQuality }) => showOnHpQuality).slice(0, 2)

    return (
      <>
        <SEO pathname={pathname} />
        <Gap gap="277px" mobileGap="150px" />
        <Container isIndex>
          <Col>
            <Title.Long as="h1" maxWidth="780px">
              Jak se určuje cena za vodu a čím musí voda projít před tím, než otočíte kohoutkem?
            </Title.Long>
            <Gap gap="35px" />
            <Text textColor="textGray">Zjistěte o vodě informace, které jste dosud netušili.</Text>
            <Gap gap="55px" />
            <Button onClick={this.scrollToContent}>Dozvědět se více</Button>
            <Gap gap="200px" mobileGap="100px" bp="tablet" />
            <Gap ref={this.contentStartRef} gap="50px" mobileGap="50px" bp="tablet" />
          </Col>

          <Col width="100%" alignItems="stretch" mobileAlignItems="center" bp="tablet">
            <Row responsive bp="tablet">
              <Col.Text>
                <Title>Cena vody</Title>
                <Gap.Title />
                <Text textColor="textGray">
                  Vodárenství je takzvaný{' '}
                  <Tooltip content="Distribuce vody je závislá na existenci vodovodní sítě. Není ekonomicky výhodné, aby takových sítí bylo několik (konkurenčních).">
                    přirozený monopol
                  </Tooltip>{' '}
                  ceny jsou regulovány státem – podléhají tzv.{' '}
                  <Tooltip content="Nadřazený orgán určí podmínky pro stanovení cen.">
                    věcnému usměrňování cen.
                  </Tooltip>{' '}
                  I když se mohou lišit podle oblasti, ve které vodu odebíráte, jedná se o nejnižší
                  z nákladů na energie a bydlení.
                </Text>
                <Gap.Paragraph />
                <Text textColor="textGray">
                  Za vodu odvádíme tzv.{' '}
                  <Tooltip content="Vodné je platba za vodu odebranou ze sítě; jako stočné označujeme poplatek za odvedení a vyčištění odpadní vody.">
                    vodné a stočné
                  </Tooltip>
                  . Běžně se určuje cena za jeden kubík (m
                  <Sup>3</Sup>
                  ). V roce 2019 byla průměrná cena za vodné 45,2&nbsp;Kč/m
                  <Sup>3</Sup> a za stočné 39,9&nbsp;Kč/m
                  <Sup>3</Sup>. Průměrná denní spotřeba na osobu byla 90,6 litrů. Denně tak za vodu
                  zaplatíme zhruba 7,71&nbsp;Kč.
                </Text>
              </Col.Text>
              <Gap gap="0" mobileGap="40px" bp="tablet" />
              <Col grow={1} justifyContent="center" alignItems="center">
                <Title.DataAmountLarge>85,1</Title.DataAmountLarge>
                <Title.DataAmountSmall lineHeight="50px">
                  Kč / m<Sup>3</Sup>
                </Title.DataAmountSmall>
                <Text.DataDescriptionLarge maxWidth="180px" textAlign="center">
                  průměrná cena vody v roce 2019
                </Text.DataDescriptionLarge>
              </Col>
            </Row>
            <Gap gap="40px" />
            <Col>
              <Link to={Link.PRICE}>
                <Button.Simple>VÍCE O CENĚ</Button.Simple>
              </Link>
            </Col>
          </Col>

          <Gap gap="100px" />

          <Row width="100%" alignItems="stretch" mobileAlignItems="center" responsive bp="tablet">
            <Box>
              <img src={ToiletSrc} alt="" />
              <Gap gap="30px" />
              <Title.DataAmountSmall>3 - 10 litrů</Title.DataAmountSmall>
              <Text.DataDescription>spotřeba za spláchnutí</Text.DataDescription>
            </Box>
            <Gap gap="50px" mobileGap="30px" bp="tablet" />
            <Box>
              <img src={ShowerSrc} alt="" />
              <Gap gap="30px" />
              <Title.DataAmountSmall>30 - 50 litrů</Title.DataAmountSmall>
              <Text.DataDescription>spotřeba za sprchování</Text.DataDescription>
            </Box>
            <Gap gap="50px" mobileGap="30px" bp="tablet" />
            <Box>
              <img src={BathSrc} alt="" />
              <Gap gap="30px" />
              <Title.DataAmountSmall>120 - 160 litrů</Title.DataAmountSmall>
              <Text.DataDescription>spotřeba za koupání</Text.DataDescription>
            </Box>
          </Row>
        </Container>

        <Gap gap="100px" />
        <Col width="100%" position="relative" id="kviz">
          <TapsBg inverted wavedBottom />
          <Waves top />
          <ChatBot />
        </Col>
        <Gap gap="120px" mobileGap="80px" bp="tablet" />

        <Container isIndex>
          <Col width="100%" alignItems="stretch" mobileAlignItems="center" bp="desktop">
            <Row responsive bp="desktop" mobileFlexDirection="column-reverse">
              <Col grow={1} justifyContent="center" alignItems="center">
                <Row
                  width="100%"
                  alignItems="center"
                  position="relative"
                  responsive
                  justifyContent="center"
                  bp="mobile"
                >
                  {pathArticles.map(({ id, title, slug, image }, key) => (
                    <Fragment key={id}>
                      <Link to={`/${slug}`}>
                        <ArticleThumbnail title={title} image={image} withButton />
                      </Link>
                      {key === 0 && pathArticles.length === 2 && <Gap gap="30px" />}
                    </Fragment>
                  ))}

                  <TapsBg
                    transparent
                    left="-20px"
                    top="20px"
                    xCount={3}
                    yCount={2}
                    leftMobile="unset"
                    topMobile="unset"
                    bottomMobile="-50px"
                    rightMobile="-50px"
                    xCountMobile={4}
                    yCountMobile={2}
                  />
                </Row>
              </Col>
              <Gap gap="60px" />
              <Col.Text>
                <Title>Cesta vody</Title>
                <Gap.Title />
                <Text>
                  Vodárny nejprve odeberou surovou vodu z přírody, následně ji kombinací různých
                  technik upraví na vodu pitnou a poté ji distribuují odběratelům – domácnostem a
                  podnikatelům.
                </Text>
                <Gap.Paragraph />
                <Text>
                  Po využití je odvedena do kanalizační sítě jako odpadní voda, vyčištěna v
                  čistírnách a navrácena zpět do přírody.
                </Text>
                <Gap gap="40px" />
                <Link to={Link.PATH}>
                  <Button.Simple>ZJISTIT VÍCE</Button.Simple>
                </Link>
              </Col.Text>
            </Row>

            <Gap gap="150px" mobileGap="100px" bp="tablet" />

            <Row responsive bp="desktop">
              <Col.Text>
                <Title>Kvalita vody</Title>
                <Gap.Title />
                <Text>
                  Na pitnou i vyčištěnou odpadní vodu, kterou čistírny vypouští do odpadních vod,
                  jsou poměrně přísné nároky. Musí splňovat řadu kritérií ohledně množství
                  chemických prvků, absence mikroorganismů i fyzikálních vlastností. Tyto parametry
                  definuje zákon, případně příbuzné vyhlášky. Časté kontroly zajišťují, že je voda
                  zdravotně nezávadná a neničí životní prostředí.
                </Text>
                <Gap gap="40px" />
                <Link to={Link.QUALITY}>
                  <Button.Simple>ZJISTIT VÍCE</Button.Simple>
                </Link>
              </Col.Text>
              <Gap gap="60px" />
              <Col grow={1} justifyContent="center" alignItems="center">
                <Row
                  width="100%"
                  position="relative"
                  alignItems="center"
                  responsive
                  justifyContent="center"
                  bp="mobile"
                >
                  {qualityArticles.map(({ id, title, slug, image }, key) => (
                    <Fragment key={id}>
                      <Link to={`/${slug}`}>
                        <ArticleThumbnail title={title} image={image} withButton />
                      </Link>
                      {key === 0 && qualityArticles.length === 2 && <Gap gap="30px" />}
                    </Fragment>
                  ))}

                  <TapsBg
                    transparent
                    left="80px"
                    top="-40px"
                    xCount={3}
                    yCount={2}
                    leftMobile="unset"
                    topMobile="unset"
                    bottomMobile="-50px"
                    rightMobile="-50px"
                    xCountMobile={4}
                    yCountMobile={2}
                  />
                </Row>
              </Col>
            </Row>
          </Col>

          <Gap gap="200px" />

          <Col width="100%" alignItems="center" textAlign="center">
            <Title.Long maxWidth="1000px">
              Nenalezli jste odpověď na svou otázku? Projděte si často kladené otázky.
            </Title.Long>
            <Gap gap="84px" />
            <Link to={Link.FAQ}>
              <Button>ČASTO SE PTÁTE</Button>
            </Link>
          </Col>
        </Container>
        <Gap gap="330px" mobileGap="250px" />
      </>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    allArticle(sort: { order: ASC, fields: sortOrder }, filter: { isPublished: { eq: true } }) {
      nodes {
        ...ArticleFragment
      }
    }
  }
`
